import axios from 'axios'

class ServicesService {
    getServices = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/services/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getLinks = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/services/links', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getIndex = (id, lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/services/' + id, {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default ServicesService