import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import error from '../../Images/error.png'
import './PageNotFound.css'

function PageNotFound() {
    const { t } = useTranslation()
    return (
        <div className='error' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - 404</title>
                <meta name="description"
                    content={t('error-description')} />
            </Helmet>
            <div className='text-center'>
                <img src={error} alt={t('error-title')} />
                <h3>{t('error-title')}</h3>
                <p className='mb-5'>{t('error-description')}</p>
                <Link className="btn" to="/">{t('error-btn')}</Link>
            </div>
        </div >
    )
}
export default PageNotFound