import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SuccessModal(props) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: "100%",
        bgcolor: '#291258',
        color: "#fff",
        border: '1px solid #fff',
        boxShadow: 24,
        borderRadius: 5,
        p: 4,
    };

    const { t } = useTranslation()

    const [posts, setPosts] = useState(props.posts || null)

    useEffect(() => {
        setPosts(props.posts)
    }, [props.posts])

    return (
        <Modal
            open={props.success}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='applic' sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <FontAwesomeIcon icon={faCheck} />  {t('alert')} <br />
                    <p className='text-center'>{t('request')} №{props.id}</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {t('waiting')}
                </Typography>
                <div className='d-flex flex-column'>
                    {posts ? posts.map(post => (
                        <Link className='mb-1' key={post.id} to={`/blog/${post.category_slug}/${post.slug}`}> {post.title}</Link>
                    )) : null}
                </div>
                <Button onClick={props.handleClose} className='btn'>{t('close-modal')}</Button>
            </Box>
        </Modal >
    )
}