import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BlogService from '../../services/BlogService'
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Article.css'

function Article(props) {
    const Blog = new BlogService()
    const [post, setPost] = useState({
        posts: []
    })

    const [pageNot, setPageNot] = useState(false)
    const { slug } = useParams()
    const ref = useRef(null);
    const { category } = useParams()

    useEffect(() => {
        ref.current.continuousStart();
        Blog.getPost(slug, props.lang).then(res => {
            setPost(res.data.post)
            ref.current.complete();
            setPageNot(false)
        })
            .catch((error) => {
                setPageNot(true)
            })
    }, [slug, props.lang])
    return (
        <div className="article">
            {pageNot ? <PageNotFound /> : <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`USOFT - ${post.title}`}</title>
                    <meta name="description"
                        content={post.title} />
                </Helmet>
                <LoadingBar
                    height={3}
                    color="red"
                    ref={ref}
                />
                <div className="container">
                    <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                                <span itemprop="name"> {t('main')}</span>
                            </Link>
                            <meta itemprop="position" content="1" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/blog">
                                <span itemprop="name"> {t('footer-blog')}</span>
                            </Link>
                            <meta itemprop="position" content="2" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/blog">
                                <span itemprop="name"> {post.category}</span>
                            </Link>
                            <meta itemprop="position" content="3" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">/
                            <span itemprop="name"> {post.title} </span>
                            <meta itemprop="position" content="4" />
                        </li>
                    </ol>
                    <div className="article-box">
                        <div className='article-image-box'>
                            <img width="1296" height="500" src={post.image} alt={post.title} />
                            <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                        </div>
                        <p className="article-mini">{post.category} | {post.created_at} | {post.time}</p>
                        <div className="article-parag" dangerouslySetInnerHTML={{ __html: post.description }}>
                        </div>
                    </div>

                    <div className="row mt-5 justify-content-center">
                        <h2>{t('read-also')}</h2>
                        {post.posts.map(v =>
                            <div key={v.id} className="col-12 col-sm-6 col-xl-3 allposts">
                                <div className="blog-posts">
                                    <Link to={`/blog/${category}/${v.slug}`}>
                                        <img width="306" height="300" src={v.image} alt={v.title} />
                                        <p>{v.title}</p>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Article
