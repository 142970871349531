import React, { useEffect, useState } from 'react'
import StagesService from "../../services/StagesService";
import { t } from 'i18next';
import './Stages.css'

function Stages(props) {
    const [stages, setStages] = useState([]);
    const stage = new StagesService()

    useEffect(() => {
        stage.getStages(props.lang).then(res => {
            setStages(res.data.development_steps)
        })
    }, [props.lang])

    return (
        <div className="container stages">
            <h2>{t('stages-title')}</h2>
            <div className="row justify-content-center">
                {stages.map(item => {
                    return (
                        <div data-aos="zoom-in" key={item.id} className="col-12 col-md-6">
                            <div className="stage-card">
                                <div className="stage-head">
                                    <img width="50" height="50" src={item.icon} alt={item.title} />
                                    <h3>{item.title}</h3>
                                </div>
                                <p>
                                    {item.body}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Stages
