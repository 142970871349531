import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SliderService from "../../services/SliderService";
import { t } from 'i18next';
import LoadingBar from "react-top-loading-bar";
import AOS from "aos";
import OrderModal from "../Modal/Modal";
import SuccessModal from "../Modal/SuccessModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';
import './Slider.css'

function SimpleSlider(props) {
    const [sliders, setSliders] = useState([])
    const [snack, setSnack] = useState(false);
    const ref = useRef(null);
    const [post, setPost] = useState({
        id: 0,
        posts: []
    })
    const handleClose = () => {
        setSnack(false)
    }
    const slider = new SliderService()

    const openSuccess = (id, posts) => {
        setSnack(true)
        setPost({ id: id, posts: posts })
    }

    useEffect(() => {
        AOS.init({
            duration: 750
        });
    }, []);

    useEffect(() => {
        ref.current.continuousStart();
        slider.getSliders(props.lang).then(res => {
            setSliders(res.data.sliders)
            ref.current.complete();
        })
    }, [props.lang])

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        sensitivity: 1.5,
        pauseOnHover: false,
        nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
        prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    };

    return (
        <div className="section1">
            <LoadingBar
                height={3}
                color="red"
                ref={ref}
            />
            <div className="container">
                <Slider {...settings}>
                    {sliders.map(slide => {
                        return (
                            <div key={slide.id}>
                                <div className="row flex-wrap-reverse d-md-flex px-2 slider-box">
                                    <div className="col-sm-5 col-12">
                                        <div className="slider-leftside">
                                            <div>
                                                <h1 className="mb-4">
                                                    {slide.title}
                                                </h1>
                                                <h2 className="parag">
                                                    {slide.subtitle}
                                                </h2>
                                            </div>
                                            <button className="btn">
                                                <OrderModal lang={props.lang} openSuccess={openSuccess} name={t('slider-btn')} modaltitle={t('application-title')} />
                                            </button>
                                            <SuccessModal lang={props.lang} id={post.id} posts={post.posts} success={snack} handleClose={handleClose} />
                                        </div>
                                    </div>
                                    <div className="col-sm-7 rightSlider col-12">
                                        <img className="sliderImage" src={slide.image} loading="lazy" alt="Image" />
                                        {
                                            slide.texts[0] ? (
                                                <div className="imageBox topbox">
                                                    <div className="imageBoxInner">
                                                        <img width="26" height="30" src={slide.texts[0].icon} alt="icon" />
                                                    </div>
                                                    <p>{slide.texts[0].body}</p>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            slide.texts[1] ? (
                                                <div className="imageBox leftbox">
                                                    <div className="imageBoxInner">
                                                        <img width="26" height="30" src={slide.texts[1].icon} alt="icon" />
                                                    </div>
                                                    <p>{slide.texts[1].body}</p>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            slide.texts[2] ? (
                                                <div className="imageBox rightbox">
                                                    <div className="imageBoxInner">
                                                        <img width="26" height="30" src={slide.texts[2].icon} alt="icon" />
                                                    </div>
                                                    <p>{slide.texts[2].body}</p>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    );
}

export default SimpleSlider;