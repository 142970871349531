import React, { useEffect,  useState } from 'react'
import StaticsService from "../../services/StaticsService";
import './NumbersBox.css'

function NumbersBox(props) {
    const [statics, setStatics] = useState([])
    const Static = new StaticsService()
    useEffect(() => {
        Static.getStatics(props.lang).then(res => {
            setStatics(res.data.statistics)
        })
    }, [ props.lang])

    return (
        <div className="container numbers">
            <div className="row justify-content-center">
                {statics.map(item => {
                    return (
                        <div data-aos="fade-up" key={item.id} className="col-lg-3 col-6">
                            <div className="numberbox">
                                <div className="numberimage" style={{ backgroundColor: `${item.color}`, boxShadow: `0 0 40px -10px ${item.color}` }}>
                                    <img width="40" height="40" src={item.icon} alt="icon" />
                                </div>
                                <h2>{item.count}</h2>
                                <span className="text-muted">{item.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default NumbersBox
