import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import PartnersService from "../../services/PartnersService";
import { t } from 'i18next';
import './Clients.css'

function Clients(props) {
    const [partners, setPartners] = useState([])
    const partner = new PartnersService()

    useEffect(() => {
        partner.getPartners(props.lang).then(res => {
            setPartners(res.data.partners)
        })
    }, [props.lang])

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        allowTouchMove: false,
        speed: 6000,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 951,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <div className="clients">
            <h2>{t('clients-title')}</h2>
            <Slider {...settings}>
                {partners.map(v =>
                    <div key={v} className="ps-5">
                        <img width="100%" height="100" src={v} alt="Partners" />
                    </div>
                )}
            </Slider>
        </div>
    );
}

export default Clients