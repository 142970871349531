import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Main from './Pages/Main/Main';
import Services from './Pages/Services/Services';
import { Website } from './Pages/Website/Website';
import Portfolio from './Pages/Portfolio/Portfolio';
import Contacts from './Pages/Contacts/Contacts';
import Vacancy from './Pages/Vacancy/Vacancy';
import ScrollTop from './Components/ScrollTop';
import Blog from './Pages/Blog/Blog';
import Sitemap from './Pages/Sitemap/Sitemap';
import Company from './Pages/Company/Company';
import Corporate from './Pages/Corporate/Corporate';
import Article from './Pages/Article/Article';
import React, { Component } from 'react';
import Policy from './Pages/Policy/Policy';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import ScrollToTop from 'react-scroll-to-top';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/index.css';
import './App.css'

class App extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        let lang = (navigator.language.slice(0, 2) === ('ru' || 'uz' || 'en'))
            ?
            JSON.parse(localStorage.getItem('Lang'))
                ?
                JSON.parse(localStorage.getItem('Lang'))
                : navigator.language.slice(0, 2)
            : 'en'
        localStorage.setItem('Lang', JSON.stringify(lang))
    }

    state = {
        lang: (navigator.language.slice(0, 2) === ('ru' || 'uz' || 'en'))
            ?
            JSON.parse(localStorage.getItem('Lang'))
                ?
                JSON.parse(localStorage.getItem('Lang'))
                : navigator.language.slice(0, 2)
            : 'en',
    }

    onChangeLang = s => {
        localStorage.setItem('Lang', JSON.stringify(s));
        this.setState({
            lang: s
        })
    }
    render() {
        return (
            <Router>
                <ScrollTop />
                <Header lang={this.state.lang} onChangeLang={this.onChangeLang} />
                <Routes>
                    <Route exact path="/" element={<Main lang={this.state.lang} />} />
                    <Route exact path="/services" element={<Services myRef={this.myRef} lang={this.state.lang} />} />
                    <Route exact path="/services/:slug" element={<Website lang={this.state.lang} />} />
                    <Route exact path="/policy" element={<Policy lang={this.state.lang} />} />
                    <Route exact path="/portfolio" element={<Portfolio lang={this.state.lang} />} />
                    <Route exact path="/portfolio/:category_id" element={<Portfolio lang={this.state.lang} />} />
                    <Route exact path="/portfolio/:category/:slug" element={<Corporate lang={this.state.lang} />} />
                    <Route exact path="/contacts" element={<Contacts lang={this.state.lang} />} />
                    <Route exact path="/vacancy" element={<Vacancy lang={this.state.lang} />} />
                    <Route exact path="/blog" element={<Blog lang={this.state.lang} />} />
                    <Route exact path="/blog/:category/:slug" element={<Article lang={this.state.lang} />} />
                    <Route exact path="/sitemap" element={<Sitemap lang={this.state.lang} />} />
                    <Route exact path="/company" element={<Company lang={this.state.lang} />} />
                    <Route exact path="/404" element={<PageNotFound />} />
                    <Route exact path="*" element={<Navigate to="/404" />} />
                </Routes>
                <ScrollToTop smooth top="400" color="#291258" />
                <Footer lang={this.state.lang} />
            </Router >
        );
    }
}

export default App;
