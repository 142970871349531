import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
import ServicesService from '../../services/ServicesService'
import './Sitemap.css'

function Sitemap(props) {
    const [service, setService] = useState([]);
    const [links, setLinks] = useState([]);
    const Services = new ServicesService()

    useEffect(() => {
        Services.getServices(props.lang).then(res => {
            setService(res.data.services)
        })
        Services.getLinks(props.lang).then(res => {
            setLinks(res.data.other_services)
        })
    }, [props.lang]);

    return (
        <div className="sitemap">
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - {t('footer-sitemap')}</title>
                <meta name="description"
                    content="Карта сайта" />
            </Helmet>
            <div className="container">
                <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                        <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                            <span itemprop="name"> {t('main')}</span>
                        </Link>
                        <meta itemprop="position" content="1" />
                    </li>
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                        <span itemprop="name"> {t('footer-sitemap')}</span>
                        <meta itemprop="position" content="2" />
                    </li>
                </ol>
                <h1>{t('footer-sitemap')}</h1>
                <div className="row justify-content-center justify-content-md-start">
                    <div className="col-md-4 col-12">
                        <div className="sitemap-box">
                            <h4>{t('services')}</h4>
                            {service.map(item =>
                                <Link to={"/services/" + item.slug}>{item.title}</Link>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="sitemap-box">
                            <h4>{t('main')}</h4>
                            <Link to="/services">{t('services')}</Link>
                            <Link to="/portfolio">{t('portfolio')}</Link>
                            <Link to="/contacts">{t('contacts')}</Link>
                            <Link to="/vacancy">{t('vacancies')}</Link>
                            <Link to="/company">{t('footer-about')}</Link>
                            <Link to="/blog">{t('footer-blog')}</Link>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="sitemap-box">
                            <h4>{t('website-development')}</h4>
                            {links.map(item =>
                                <Link to={"/services/" + item.slug}>{item.title}</Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sitemap
