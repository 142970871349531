import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import ProjectsService from "../../services/ProjectService";
import { useTranslation } from 'react-i18next';
import './Ourworks.css'

function Ourworks(props) {
    const [value, setValue] = useState('1')
    const { t } = useTranslation()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const Project = new ProjectsService()
    const [category, setCategory] = useState([
        {
            category: '',
            projects: [],
            images: []
        }
    ])

    const { slug } = useParams()
    const getCategories = lang => {
        Project.getCategories(lang, 5).then(res => {
            setCategory(res.data.categories.map(function (item) {
                switch (item.category) {
                    case 'popular':
                        return {
                            category: item.category,
                            title: t('popular'),
                            projects: item.projects,
                            images: item.images
                        }
                    case 'web':
                        return {
                            category: item.category,
                            title: t('web'),
                            projects: item.projects
                        }
                    case 'mobile':
                        return {
                            category: item.category,
                            title: t('mobile'),
                            projects: item.projects
                        }
                    default:
                        return {
                            category: item.category,
                            title: t('avto'),
                            projects: item.projects
                        }
                }
            }))
        })
    }
    useEffect(() => {
        getCategories(props.lang)
    }, [slug, props.lang])

    return (
        <div className="container works">
            <Box sx={{ typography: 'body1' }}>
                <TabContext value={value}>
                    <Box>
                        <h2>{t('ourworks-title')}</h2>
                        <TabList onChange={handleChange} variant="scrollable" aria-label="scrollable prevent tabs example" >
                            {category.map((item, index) =>
                                <Tab disableRipple key={index} label={item.title} value={String(index + 1)} />
                            )}
                        </TabList>
                    </Box>
                    {category.map((item, index) =>
                        <TabPanel key={index} value={String(index + 1)}>
                            <div className="row">
                                {item.projects.map(v =>
                                    <div key={v.id} className="main-box col-md-6 d-flex d-lg-block flex-wrap justify-content-center col-12">
                                        <div className="work-card">
                                            <Link to={`/portfolio/${v.category}/${v.slug}`}><h3>{v.title}</h3></Link>
                                            <div data-aos="zoom-in" className="work-card-layout">
                                                <img src={v.image} alt={v.title} />
                                                <Link className="portfolio-layout" to={`/portfolio/${v.category}/${v.slug}`}>{t('about')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {item.category == 'popular' ?
                                    <div className="main-box col-md-6 d-flex d-lg-block flex-wrap justify-content-center col-12">
                                        <div className="work-card">
                                            <Link to="/portfolio"><h3>{t('ourworks-all')}</h3></Link>
                                            <div data-aos="zoom-in" className="work-card-layout main-net">
                                                <div className='row net'>
                                                    {item.images.map(i => (
                                                        <div className='col-4'>
                                                            <img src={i} alt="all" />
                                                        </div>
                                                    ))}
                                                </div>
                                                <Link className="portfolio-layout" to="/portfolio">{t('about')}</Link>
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>
                        </TabPanel>
                    )}
                    <Link className="allworks" to="/portfolio">{t('ourworks-all')}<FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                </TabContext>
            </Box>

        </div>
    )
}

export default Ourworks
