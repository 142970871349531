import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTelegramPlane, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import SettingsService from "../../services/SettingsService";
import InputMask from "react-input-mask";
import ApplicationService from '../../services/ApplicationService';
import { useTranslation } from 'react-i18next';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import SuccessModal from '../Modal/SuccessModal';
import './Footer.css'

function Footer(props) {
    const [open, setOpen] = useState(false);
    const [settings, setSettings] = useState([]);
    const [pend, setPend] = useState(false)
    const [phone, setPhone] = useState()
    const [post, setPost] = useState({
        id: 0,
        posts: []
    })

    const setting = new SettingsService()
    const application = new ApplicationService()

    const onChange = (event) => {
        setPhone(event.target.value)
    }
    const { t } = useTranslation()

    useEffect(() => {
        setting.getSettings(props.lang)
            .then(res => {
                setSettings(res.data.settings)
            })
    }, [props.lang])

    const handleClose = () => {
        setPhone("")
        setOpen(false);
    }

    const sendApplication = event => {
        event.preventDefault()
        setPend(true)
        event.target.reset()
        application.createApplication(phone, props.lang)
            .then(response => {
                setPost({
                    id: response.data.id,
                    posts: response.data.posts
                })
                setOpen(true);
                setPend(false)
            })
    }

    let telephone = ''
    if (settings.phone) {
        telephone = settings.phone.map(phone => {
            return (
                <a key={phone} href={`tel:${phone}`}>{phone}</a>
            )
        })
    }

    const override = css`
        display: flex;
        align-items: center;
        justify-content:center;
        border-color: red;
        height: 50px;
        width: 120px;
        min-width: 100%;
     `;

    return (
        <footer>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-3 col-6  mb-5 d-flex justify-content-center">
                        <div className="col-md-12 col-12 text-center text-md-start">
                            <h4>{t('footer-contact')}</h4>
                            <h5>{t('footer-adress')}</h5>
                            <a
                                className="footer-adress"
                                target="_blank"
                                rel="noreferrer"
                                href={settings.location}>
                                {settings.address}
                            </a>
                            <h5>{t('footer-mail')}</h5>
                            <a
                                className="footer-adress"
                                href={`mailto:${settings.email}`}>
                                {settings.email}
                            </a>
                            <div className="call">
                                {telephone}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-6  ps-md-5 ps-3 mb-5 d-flex justify-content-center">
                        <div className="col-md-12 col-12 text-center text-md-start">
                            <h4><Link to='./sitemap'>{t('footer-sitemap')}</Link></h4>
                            <ul className='footer-links'>
                                <li><Link to="/services">{t('footer-services')}</Link></li>
                                <li><Link to="/portfolio">{t('footer-portfolio')}</Link></li>
                                <li><Link to="/blog">{t('footer-blog')}</Link></li>
                                <li><Link to="/vacancy">{t('footer-vacancies')}</Link></li>
                                <li><Link to="/contacts">{t('footer-contact')}</Link></li>
                                <li><Link to="/company">{t('footer-about')}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 mb-5 d-flex justify-content-center">
                        <div className="col-md-12 col-8 text-center text-md-start">
                            <h4>{t('application-title')}</h4>
                            <form className="footer-form" onSubmit={sendApplication}>
                                <InputMask
                                    mask='+\9\9\8 (99) 999-99-99'
                                    disabled={false}
                                    maskChar={null}
                                    value={phone}
                                    onChange={onChange}
                                >
                                    {() => <TextField className='text-input'
                                        label={t('telephone')}
                                        required
                                        id="phone" />}
                                </InputMask>
                                <SuccessModal
                                    lang={props.lang}
                                    id={post.id}
                                    posts={post.posts}
                                    success={open}
                                    handleClose={handleClose}
                                />
                                <div>
                                    {pend ? <BeatLoader
                                        css={override}
                                        color={'#ff7878'}
                                        loading={pend}
                                        size={20}
                                    /> :
                                        <button
                                            className="btn"
                                            type="submit">
                                            {t('send-request')}
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 footer-bottom">
                        <p className="mb-0">{new Date().getFullYear()} USOFT "ROUND UMBRELLA" LLC</p>
                        <div className="icon-links">
                            {
                                settings.social ? <a href={settings.social.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a> : null
                            }
                            {
                                settings.social ? <a href={settings.social.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a> : null
                            }
                            {
                                settings.social ? <a href={settings.social.telegram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTelegramPlane} /></a> : null
                            }
                            {
                                settings.social ? <a href={settings.social.linkedin} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
