import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import TextField from "@material-ui/core/TextField";
import VacancyService from '../../services/VacancyService';
import ApplicationService from '../../services/ApplicationService'
import VacancyModal from '../../Components/Modal/VacancyModal'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import InputMask from "react-input-mask";
import { useTranslation } from 'react-i18next';
import SuccessModal from '../../Components/Modal/SuccessModal';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import './Vacancy.css'

function Vacancy(props) {
    const [open, setOpen] = useState(false);
    const [vacancy, setVacancy] = useState([])
    const [pend, setPend] = useState(false)
    const [phone, setPhone] = useState()
    const [snack, setSnack] = useState(false)
    const ref = useRef(null);
    const [post, setPost] = useState({
        id: 0,
        posts: []
    })

    const { t } = useTranslation()

    const Vacancy = new VacancyService()
    const application = new ApplicationService()

    const onChange = (event) => {
        setPhone(event.target.value)
    }

    const submit = event => {
        event.preventDefault()
        setPend(true)
        event.target.reset()
        application.createApplication(phone, props.lang).then(res => {
            setPost({
                id: res.data.id,
                posts: res.data.posts
            })
            setOpen(true);
            setPend(false)
        })
    }

    const openSuccess = (id, posts) => {
        setPost({ id: id, posts: posts })
        setSnack(true)
    }

    const closeSuccess = () => {
        setSnack(false)
    }

    const handleClose = () => {
        setPhone("")
        setOpen(false);
    }

    useEffect(() => {
        ref.current.continuousStart();
        Vacancy.getVacancy(props.lang).then(res => {
            setVacancy(res.data.vacancies)
            ref.current.complete();
        })
    }, [props.lang]);

    //button readmore
    const LongText = ({ vacancy, limit }) => {
        if (vacancy.body.length <= limit) {
            return <div dangerouslySetInnerHTML={{ __html:vacancy.body }}></div>
        } else {
            const toShow = vacancy.body.substring(0, limit) + "...";
            return <div>
                <div dangerouslySetInnerHTML={{ __html: toShow }}></div>
                <VacancyModal readStyle={{ maxWidth: 1000, height: 650 }}
                    content={vacancy.body}
                    clazz="read-modal"
                    openSuccess={openSuccess}
                    name={t('read-full-description')}
                    modaltitle={t('vacancy-modal-title')}
                    vacancy_id={vacancy.id}
                    positionTitle={vacancy.position}
                />
            </div>
        }
    }

    const btnn = css`
    display: flex;
    align-items: center;
    justify-content:center;
    border-color: red;
    width: 120px;
    min-width: 100%;
`;

    return (
        <div className="vacancy">
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - {t('vacancies')}</title>
                <meta name="description"
                    content="Вакансии для программистов в Ташкенте" />
            </Helmet>
            <LoadingBar
                height={3}
                color="red"
                ref={ref}
            />
            <div className="container">
                <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                        <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                            <span itemprop="name"> {t('main')}</span>
                        </Link>
                        <meta itemprop="position" content="1" />
                    </li>
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                        <span itemprop="name"> {t('vacancies')}</span>
                        <meta itemprop="position" content="2" />
                    </li>
                </ol>
                <h1>{t('vacancies')}</h1>
                <div className="row">
                    {vacancy.map(i =>
                        <div key={i.id} className="col-md-6 col-12 mb-4">
                            <div className="vacancy-box">
                                <div>
                                    <h2>{i.position}</h2>
                                    <div className='vacancy-info'>
                                        <LongText vacancy={i} limit={300} />
                                    </div>
                                </div>
                                <VacancyModal className="btn"
                                    style={{ backgroundColor: `${i.color}`, boxShadow: `0 0 40px -10px ${i.color}` }}
                                    lang={props.lang}
                                    openSuccess={openSuccess}
                                    name={t('send-vacancies')}
                                    modaltitle={t('vacancy-modal-title')}
                                    vacancy_id={i.id}
                                    positionTitle={i.position}
                                />
                            </div>
                        </div>
                    )}
                    <SuccessModal lang={props.lang} id={post.id} posts={post.posts} success={snack} handleClose={closeSuccess}></SuccessModal>
                </div>
                <div className="contacts-form">
                    <h2>{t('vacancies-app-title')}</h2>
                    <p>{t('vacancies-app-bottom')}</p>
                    <form className="app-send-box" onSubmit={submit}>
                        <InputMask
                            mask='+\9\9\8 (99) 999-99-99'
                            disabled={false}
                            maskChar={null}
                            value={phone}
                            onChange={onChange}
                        >
                            {() => <TextField className='text-input'
                                label={t('telephone')}
                                id="vacancy-phone"
                                required />
                            }
                        </InputMask>
                        <SuccessModal lang={props.lang} id={post.id} posts={post.posts} success={open} handleClose={handleClose}></SuccessModal>
                        <div className='send-hover'>
                            {pend ? <BeatLoader
                                css={btnn}
                                color={'#ff7878'}
                                loading={pend}
                                size={20}
                            /> :
                                <button type="submit" variant="inf" className="send">{t('send-request')} <FontAwesomeIcon icon={faLongArrowAltRight} /></button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Vacancy
