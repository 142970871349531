import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick';
import Comment from '../../Components/Comment/Comment'
import Application from '../../Components/Application/Application'
import ProjectService from '../../services/ProjectService'
import Fade from "react-reveal/Fade";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LoadingBar from 'react-top-loading-bar';
import './Corporate.css'

function Corporate(props) {
    const settings = {
        centerMode: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        speed: 6000,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 951,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const { t } = useTranslation()
    const { slug } = useParams()
    const { category } = useParams()
    const navigate = useNavigate()
    const ref = useRef(null);
    const [fade, setFade] = useState(false)
    const [project, setProject] = useState({
        characters: [],
        stacks: [],
        texts: [],
        feedbacks: []
    });

    const Project = new ProjectService()

    useEffect(() => {
        getFeedbacks()
    }, [props.lang]);

    const getCategory = () => {
        switch (category) {
            case 'web':
                return t('web')
            case 'mobile':
                return t('mobile')
            default:
                return t('avto')
        }
    }
    const getFeedbacks = () => {
        setFade(false)
        ref.current.continuousStart();
        Project.getIndex(slug, props.lang).then(res => {
            setProject(res.data.project)
            ref.current.complete();
            setFade(true)
        })
            .catch((error) => {
                return navigate('/404')
            })
    }
    return (
        <div className="corporate">
            <LoadingBar
                height={3}
                color="red"
                ref={ref}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`USOFT - ${project.title}`}</title>
                <meta name="description"
                    content={project.title} />
            </Helmet>
            <div className="corporate-header" style={{ backgroundColor: `${project.color}` }}>
                <div className="container">
                    <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                                <span itemprop="name"> {t('main')}</span>
                            </Link>
                            <meta itemprop="position" content="1" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">/
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/portfolio">
                                <span itemprop="name"> {t('portfolio')}</span>
                            </Link>
                            <meta itemprop="position" content="2" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">/
                            <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to={`/portfolio/${category}`}>
                                <span itemprop="name"> {getCategory()}</span>
                            </Link>
                            <meta itemprop="position" content="3" />
                        </li>
                        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">/
                            <span itemprop="name"> {project.title} </span>
                            <meta itemprop="position" content="4" />
                        </li>
                    </ol>
                    <div className="row">
                        <div className="col-lg-6 col-12 ps-0">
                            <div className="ps-3">
                                <h1>{project.subtitle}</h1>
                                <p className="corporate-title"></p>
                            </div>
                            <Fade when={fade} left cascade>
                                <div className="index">
                                    <div className="grid">
                                        <p className="grid-parag">
                                            {project.characters[0] ? project.characters[0].title : ''}
                                        </p>
                                        <div className="grid-number">
                                            {project.characters[0] ? project.characters[0].value : ''}
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <p className="grid-parag">
                                            {project.characters[1] ? project.characters[1].title : ''}
                                        </p>
                                        <div className="grid-number">
                                            {project.characters[1] ? project.characters[1].value : ''}
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <p className="grid-parag">
                                            {project.characters[2] ? project.characters[2].title : ''}
                                        </p>
                                        <div className="grid-number">
                                            {project.characters[2] ? project.characters[2].value : ''}
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <p className="grid-parag">
                                            {project.characters[3] ? project.characters[3].title : ''}
                                        </p>
                                        <div className="grid-number">
                                            {project.characters[3] ? project.characters[3].value : ''}
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6 col-12 corporate-img">
                            <img width="564" height="630" src={project.image} alt={project.title} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="corporate-section">
                <div className="container">
                    <Slider {...settings}>
                        {project.stacks.map(v =>
                            <div key={v.id}  >
                                <img src={v} alt="stack" />
                            </div>
                        )}
                    </Slider>
                    {project.texts.map((text, index) => {
                        if (index % 2 == 1) {
                            return (
                                <div className="row flex-column-reverse flex-md-row align-items-center justify-content-center mt-5">
                                    <div className="col-md-8 col-12 corporate-col-parag">
                                        <h4 className="text-center text-md-start">{text.title}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: text.body }}></div>
                                    </div>
                                    <div className="col-md-4 col-12 corporate-col-img text-center">
                                        <img width="342" height="342" src={text.image} alt={text.title} />
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="row align-items-center justify-content-center mt-5">
                                    <div className="col-md-4 col-12 corporate-col-img text-center">
                                        <img width="342" height="342" src={text.image} alt={text.title} />
                                    </div>
                                    <div className="col-md-8 col-12 corporate-col-parag">
                                        <h4 className="text-center text-md-start">{text.title}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: text.body }}></div>
                                    </div>
                                </div>)
                        }
                    })}
                </div>
            </div>
            <Application lang={props.lang} />
            <Comment comments={project.feedbacks} />
        </div>
    )
}
export default Corporate
