import axios from 'axios'

class ApplicationService {
    url = process.env.REACT_APP_API_URL
    createApplication = (phone, lang = "uz", service_id = null) => {
        const form = new FormData()
        form.append('phone', phone)
        if (service_id) form.append('service_id', service_id)
        return axios.post(this.url + 'application/', form, {
            headers: {
                Locale: lang.toLowerCase(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }
}

export default ApplicationService