import React, { useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import "./fancybox.css"

function Fancybox(props) {
    const delegate = "[data-fancybox]";

    useEffect(() => {
        NativeFancybox.bind(delegate);

        return () => {
            NativeFancybox.destroy();
        };
    }, []);

    let elements = '';
    if (props.type === 'certificate') {
        elements = props.data.map((item, index) => {
            return (
                <div className="col-lg-4 col-sm-6" key={index}>
                    <a data-fancybox={props.dataFans} href={item}>
                        <img width="360" height="420" src={item} alt="Certificate" />
                    </a>
                </div>
            )
        })
    }
    // else {
    //     elements = props.data.map(item => {
    //         return (
    //             <div className="col-lg-3 col-sm-6" key={item.id}>
    //                 <a data-fancybox={props.dataFans} href={item.image}>
    //                     <img src={item.image} alt="?" />
    //                 </a>
    //             </div>
    //         )
    //     })
    // }
    return (
        <>
            {props.children}
            <div className="row text-center fancy-box">
                {elements}
            </div>
        </>

    )
}

export default Fancybox;
