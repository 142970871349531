import React from 'react'
import comas from '../../Images/coma.png'
import left from '../../Images/arrow_left_icon.svg'
import right from '../../Images/arrow_right_icon.svg'
import Slider from 'react-slick';
import { t } from 'i18next';
import './Comment.css'

function Comment(props) {
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <img src={right} alt=">" />,
        prevArrow: <img src={left} alt="<" />,
    };
    return (
        <div className="container comment">
            <h2>{t('comments-title')}</h2>
            <Slider {...settings}>
                {props.comments.map(v => {
                    return (
                        <div key={v.id} className="px-3">
                            <div className="comment-box">
                                <img width="138" height="117" className="coma" src={comas} alt="coma" />
                                <p> {v.body}</p>
                                <div className="user">
                                    {v.image != '#' ? <img width="65" height="65" src={v.image} alt={v.company} /> : null}
                                    <div className="ms-3">
                                        <h4>{v.client}</h4>
                                        <span>{v.company}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}


export default Comment