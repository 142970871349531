import React, { useState, useEffect } from 'react';
import ServicesService from "../../services/ServicesService";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import LoadingBar from 'react-top-loading-bar';
import './Services.css'

function Services(props) {
    const [service, setService] = useState([
    ])
    const Service = new ServicesService()
    useEffect(() => {
        props.myRef.current.continuousStart();
        Service.getServices(props.lang).then(res => {
            setService(res.data.services)
            props.myRef.current.complete();
        })
    }, [props.lang])

    return (
        <div className="services">
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - {t('services')}</title>
                <meta name="description"
                    content="Наши услуги" />
            </Helmet >
            <LoadingBar
                height={3}
                color="red"
                ref={props.myRef}
            />
            <div className="container">
                <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                        <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                            <span itemprop="name">{t('main')}</span>
                        </Link>
                        <meta itemprop="position" content="1" />
                    </li>
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                        <span itemprop="name"> {t('services')}</span>
                        <meta itemprop="position" content="2" />
                    </li>
                </ol>
                <h1>{t('services')}</h1>
                <div className="row justify-content-center">
                    {service.map(v =>
                        <div key={v.id} className="col-lg-4 col-md-6 col-sm-7 mb-4">
                            <div className="pricebox">
                                <div className="title">
                                    <h2>{v.title} </h2>
                                    <img width="190" height="190" src={v.image} alt={v.title} />
                                    <p>{v.short_description}</p>
                                    <Link style={{ backgroundColor: `${v.color}`, boxShadow: `0 0 40px -10px ${v.color}` }} to={`/services/${v.slug}`} className="btn">{t('go-to')}</Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default Services
