import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import SettingsService from "../../services/SettingsService";
import Appform from '../../Components/Appform/Appform';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import { t } from 'i18next';
import './Contacts.css'

function Contacts(props) {
    const [settings, setSettings] = useState({})
    const setting = new SettingsService()
    const ref = useRef(null);
    useEffect(() => {
        ref.current.continuousStart();
        setting.getSettings(props.lang).then(res => {
            setSettings(res.data.settings)
            ref.current.complete();
        })
    }, [props.lang])

    let telephone = ''
    if (settings.phone) {
        telephone = settings.phone[0]
    }

    return (
        <div className="contacts">
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - {t('contacts')}</title>
                <meta name="description"
                    content="Тел: +998 71 200 12 00, Адрес: ул. Афросиаб 6/1" />
            </Helmet>
            <LoadingBar
                height={3}
                color="red"
                ref={ref}
            />
            <div itemscope itemtype="http://schema.org/Organization" className="container">
                <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                        <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                            <span itemprop="name"> {t('main')}</span>
                        </Link>
                        <meta itemprop="position" content="1" />
                    </li>
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"> /
                        <span itemprop="name"> {t('contacts')}</span>
                        <meta itemprop="position" content="2" />
                    </li>
                </ol>
                <h1 itemprop="name">{t('contacts')}</h1>
                <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress" className="contacts-about">
                    <p>{t('contact-mail')}: <a href={`mailto:${settings.email}`}> <span itemprop="email">{settings.email}</span></a></p>
                    <p>{t('contact-facebook')} <a href="https://www.facebook.com/umbrellasoftuz">facebook</a></p>
                    <p><a href='#tell'>{t('contact-phone1')}</a> {t('contact-phone')} <a href={`tel:${telephone}`}><span itemprop="telephone">{telephone}</span></a></p>
                    <p>{t('contact-time')} {settings.working_time}</p>
                    <p> <span itemprop="streetAddress">{t('contact-adress')}: {settings.address}</span></p>
                </div>
                <div className="contacts-map">
                    <iframe title="Map" src={settings.location} width="100%" height="450" loading="lazy"></iframe>
                </div>
                <Appform lang={props.lang} />
            </div>
        </div>
    )
}

export default withTranslation()(Contacts)
