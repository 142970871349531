import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Prices from '../../Components/Prices/Prices'
import Fancybox from '../../Components/Imageviewer/fancybox'
import AboutService from '../../services/AboutService';
import Appform from '../../Components/Appform/Appform';
import Aos from 'aos';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import './Company.css'

function Company(props) {
    const ref = useRef(null);
    const [about, setAbout] = useState({
        certificates: [],
        description: [],
        feedbacks: []
    });

    const About = new AboutService()

    useEffect(() => {
        ref.current.continuousStart();
        About.getAbout(props.lang).then(res => {
            setAbout(res.data.about)
            ref.current.complete();
        })
        Aos.init({
            duration: 1000
        });
    }, [props.lang]);
    return (
        <div className="company">
            <Helmet>
                <meta charSet="utf-8" />
                <title>USOFT - {t('footer-about')}</title>
                <meta name="description" content="Способствовать развитию и продвижению компаний в Интернете, улучшая качество предоставляемых услуг и повышая их конкурентоспособность и устойчивость перед западными партнерами." />
            </Helmet>
            <LoadingBar
                height={3}
                color="red"
                ref={ref}
            />
            <div className="container">
                <ol itemscope itemtype="http://schema.org/BreadcrumbList" className="breadcrumb">
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                        <Link itemscope itemtype="http://schema.org/Thing" itemprop="item" to="/">
                            <span itemprop="name"> {t('main')}</span>
                        </Link>
                        <meta itemprop="position" content="1" />
                    </li>
                    <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">/
                        <span itemprop="name"> {t('footer-about')}</span>
                        <meta itemprop="position" content="2" />
                    </li>
                </ol>
                <h1>{t('footer-about')} </h1>
                <div className="row justify-content-center">
                    {about.description.map(item =>
                        <div data-aos="fade-up" key={item.id} className="col-lg-4 col-md-6 col-sm-7 mb-4">
                            <div className="companybox">
                                <div className="companyimage" style={{ backgroundColor: `${item.color}`, boxShadow: `0 0 40px -10px ${item.color}` }}>
                                    <img width="30" height="30" src={item.image} alt={item.title} />
                                </div>
                                <h2 className="companytitle">{item.title}</h2>
                                <p className="company-parag">{item.body}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <Prices lang={props.lang} />
                <h2 className="company-head">{t('awards')}</h2>
                <Fancybox data={about.certificates} type="certificate" dataFans="gall1" />
                {(about.feedbacks.id) ? < div >
                    <h2 className="company-head">Благодарственные письма</h2>
                    <Fancybox data={about.feedbacks} type="feedbacks" dataFans="gall2" />
                </div> : null
                }
                <Appform lang={props.lang} />
            </div>
        </div >
    )
}

export default Company
