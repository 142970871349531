import axios from 'axios'

class AboutService {
    getAbout = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/about/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default AboutService